export enum EoiTemplateFields {
  LAND_LOAN_AMOUNT = "Land loan amount",
  STATE = "State",
  CONTRACT_SUBJECT_TO_FINANCE = "Contract subject to finance?",
  FINANCE_BROKER_DETAILS = "Finance broker details",
  SUBURB = "Suburb",
  POSTCODE = "Postcode",
  NAME = "Name",
  ABN_ACN = "ABN / ACN",
  REGISTERED_ADDRESS = "Registered Address",
}
