import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/del-selected-file.svg'


const _withScopeId = n => (_pushScopeId("data-v-48f091b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 1,
  class: "spinner spinner-small"
}
const _hoisted_3 = ["id", "accept"]
const _hoisted_4 = { class: "dropdown" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["DropDown"], null, {
    control: _withCtx(() => [
      _createElementVNode("button", {
        class: "input dropdown-input",
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
      }, [
        (!$props.loading)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass([{ 'selected-file': $setup.dropDownValue !== $props.placeholder }, { 'text-muted': $setup.dropDownValue === $props.placeholder }])
            }, [
              _createTextVNode(_toDisplayString($setup.dropDownValue), 1),
              ($setup.dropDownValue !== $props.placeholder)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "del-file",
                    src: _imports_0,
                    alt: "Delete file",
                    onClick: _withModifiers($setup.deleteFile, ["stop"])
                  }, null, 8, _hoisted_1))
                : _createCommentVNode("", true)
            ], 2))
          : (_openBlock(), _createElementBlock("div", _hoisted_2))
      ]),
      _createElementVNode("input", {
        class: "file-input",
        type: "file",
        id: `dropdown-input-file-${$props.owner}`,
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('uploadFile', $event, $props.owner))),
        accept: $setup.imgType
      }, null, 40, _hoisted_3)
    ]),
    dropdown: _withCtx(() => [
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            onClick: ($event: any) => (_ctx.$emit('changeSelectFile', item))
          }, [
            _createElementVNode("span", {
              class: _normalizeClass([{ 'icon-file': item.name != $props.uploadNewItem }])
            }, _toDisplayString(item.name), 3)
          ], 8, _hoisted_5))
        }), 256)),
        _createElementVNode("li", {
          class: "without-padding",
          onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createElementVNode("label", {
            for: `dropdown-input-file-${$props.owner}`
          }, _toDisplayString($props.uploadNewItem), 9, _hoisted_6)
        ])
      ])
    ]),
    _: 1
  }))
}